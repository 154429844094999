import { Icon } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { SidenavItem } from '~/components/Sidenav/SidenavItem'
import { useIsDevice } from '~/hooks/useIsDevice'
import { useUI } from '~/hooks/useUI'
import { ProtectedComponent } from '../ProtectedComponent'
import { VinciButton } from '../VinciButton'
import * as S from './Sidenav.styles'

export const Sidenav = () => {
  const {
    sidenavOpen,
    sidenavCollapsed,
    setSidenavCollapsed,
    setSidenavOpen,
    sidenavAlertDismissed,
    setSidenavDismissed,
  } = useUI()
  const { isDesktop, isTablet, isMobile } = useIsDevice()
  const { t } = useTranslation()
  const IS_DATE_BEFORE_MAINTENANCE_GAP =
    new Date().getTime() < new Date('2025-01-12T00:00:00-05:00').getTime()
  const shouldShowAlert = !sidenavAlertDismissed && IS_DATE_BEFORE_MAINTENANCE_GAP

  React.useLayoutEffect(() => {
    if (isTablet) {
      setSidenavCollapsed(true)
      setSidenavOpen(false)
    }
    if (isDesktop) {
      setSidenavCollapsed(false)
      setSidenavOpen(true)
    }
    if (isMobile) {
      setSidenavCollapsed(false)
      setSidenavOpen(false)
    }
  }, [isDesktop, isTablet, isMobile, setSidenavCollapsed, setSidenavOpen])

  const handleCloseSidenav = () => {
    setSidenavOpen(false)
  }

  const handleDismissAlert = () => {
    setSidenavDismissed(true)
  }

  const shouldShowEnvironment = React.useMemo(() => !['prod'].includes(ENVIRONMENT), [])
  const environmentText = React.useMemo(() => {
    const environmentMap: Record<string, string> = {
      demo: 'DEMO VERSION',
      development: 'DEVELOPMENT VERSION',
      preview: 'PREVIEW VERSION',
      macquarie: 'DEMO MACQUARIE',
    }

    return environmentMap[ENVIRONMENT] || ''
  }, [])

  return (
    <S.Wrapper
      variant={isMobile ? 'temporary' : 'persistent'}
      open={sidenavOpen}
      onClose={handleCloseSidenav}
      collapsed={sidenavCollapsed ? `${sidenavCollapsed}` : null}
    >
      {isMobile && (
        <S.CloseButton size='small' onClick={handleCloseSidenav}>
          <Icon>close</Icon>
        </S.CloseButton>
      )}
      <S.InternalWrapper collapsed={sidenavCollapsed ? `${sidenavCollapsed}` : null}>
        <S.LogoWrapper>
          {!sidenavCollapsed ? (
            <img src={'/full-size-logo.webp'} width='100%' alt='Eye looking at a plane' />
          ) : (
            <img src={'/icon-logo.webp'} width='90%' alt='Eye looking at a plane' />
          )}
        </S.LogoWrapper>

        {shouldShowEnvironment && !sidenavCollapsed && (
          <>
            <S.Divider />
            <S.EnvironmentText>{environmentText}</S.EnvironmentText>
          </>
        )}

        <S.Divider />
        <ProtectedComponent requiredPermission='list_asset_as_manager'>
          <SidenavItem name={t('Assets')} icon={'flight'} to={'/assets'} />
        </ProtectedComponent>
        <ProtectedComponent requiredPermission='list_inspection'>
          <SidenavItem name={t('Audits')} icon={'assignment'} to={'/audit'} />
        </ProtectedComponent>
        <ProtectedComponent
          requiredPermission={{
            operation: 'or',
            permissions: ['list_user', 'list_company', 'list_asset'],
          }}
        >
          <SidenavItem name={t('General Settings')} type={'collapse'} icon={'settings'}>
            <ProtectedComponent requiredPermission='list_user'>
              <SidenavItem name={t('Users')} icon={'person'} to={'/users'} />
            </ProtectedComponent>
            <ProtectedComponent requiredPermission='list_company'>
              <SidenavItem name={t('Companies')} icon={'business'} to={'/companies'} />
            </ProtectedComponent>
            <ProtectedComponent requiredPermission='list_asset'>
              <SidenavItem name={t('Assets Config')} icon={'flight'} to={'/assets_config'} />
            </ProtectedComponent>
          </SidenavItem>
        </ProtectedComponent>
        <ProtectedComponent
          requiredPermission={{
            operation: 'or',
            permissions: [
              'list_report_template',
              'list_inspection_checklist',
              'list_inspection_item',
              'list_valuation_item',
              'list_component',
              'list_component_parameter',
              'list_physical_item',
            ],
          }}
        >
          <SidenavItem name={t('Audit Settings')} type={'collapse'} icon={'widgets'}>
            <ProtectedComponent requiredPermission='list_report_template'>
              <SidenavItem
                name={t('Report Templates')}
                icon={'description'}
                to={'/report_templates'}
              />
            </ProtectedComponent>
            <ProtectedComponent requiredPermission='list_inspection_checklist'>
              <SidenavItem
                name={t('Checklists')}
                icon={'checklist'}
                to={'/inspection_checklists'}
              />
            </ProtectedComponent>
            <ProtectedComponent requiredPermission='list_inspection_item'>
              <SidenavItem name={t('Audit Items')} icon={'list'} to={'/inspection_items'} />
            </ProtectedComponent>
            <ProtectedComponent requiredPermission='list_component'>
              <SidenavItem name={t('Asset Components')} icon={'handyman'} to={'/components'} />
            </ProtectedComponent>
            <ProtectedComponent requiredPermission='list_file_class'>
              <SidenavItem name={t('File Classes')} icon={'topic'} to={'/file_classes'} />
            </ProtectedComponent>
            <ProtectedComponent requiredPermission='list_component_parameter'>
              <SidenavItem
                name={t('Compts. Parameters')}
                icon={'question_answer'}
                to={'/component_parameters'}
              />
            </ProtectedComponent>
            <ProtectedComponent requiredPermission='list_physical_item'>
              <SidenavItem
                name={t('Physical Ver. Items')}
                icon={'hardware'}
                to={'/physical_items'}
              />
            </ProtectedComponent>
          </SidenavItem>
        </ProtectedComponent>
        {shouldShowAlert && (
          <S.DismissableAlertWrapper>
            <S.DismissableAlert>
              <S.DismissableAlertTitle>{t('Maintenance Alert')}</S.DismissableAlertTitle>
              <S.DismissableAlertDescription>
                {t('Scheduled maintenance on Jan 12th, 12:00 AM EST. Brief instability expected.')}
              </S.DismissableAlertDescription>
              <VinciButton onClick={handleDismissAlert}>Dismiss</VinciButton>
            </S.DismissableAlert>
          </S.DismissableAlertWrapper>
        )}
      </S.InternalWrapper>
    </S.Wrapper>
  )
}
