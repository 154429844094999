import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '~/store'
import {
  changeSidenavAlertDismissed,
  changeSidenavCollapsed,
  changeSidenavOpen,
  toggleSidenavCollapsed as toggleSidenavCollapsedAction,
  toggleSidenavOpen as toggleSidenavOpenAction,
} from '~/store/slices/uiSlice'

export const useUI = () => {
  const dispatch = useAppDispatch()
  const { sidenavOpen, sidenavCollapsed, sidenavAlertDismissed } = useAppSelector(
    (store) => store.ui,
  )

  const toggleSidenavOpen = useCallback(() => {
    dispatch(toggleSidenavOpenAction())
  }, [dispatch])

  const toggleSidenavCollapsed = useCallback(() => {
    dispatch(toggleSidenavCollapsedAction())
  }, [dispatch])

  const setSidenavCollapsed = useCallback(
    (collapsed: boolean) => {
      dispatch(changeSidenavCollapsed(collapsed))
    },
    [dispatch],
  )

  const setSidenavOpen = useCallback(
    (opened: boolean) => {
      dispatch(changeSidenavOpen(opened))
    },
    [dispatch],
  )
  const setSidenavDismissed = useCallback(
    (dismissed: boolean) => {
      dispatch(changeSidenavAlertDismissed(dismissed))
    },
    [dispatch],
  )

  return {
    sidenavOpen,
    sidenavCollapsed,
    toggleSidenavOpen,
    toggleSidenavCollapsed,
    setSidenavCollapsed,
    setSidenavOpen,
    setSidenavDismissed,
    sidenavAlertDismissed,
  }
}
