import { createTheme } from '@mui/material'

import { breakpoints } from './base/breakpoints'
import { palette } from './base/palette'
import { shadows } from './base/shadows'
import { typography } from './base/typography'

import { MUIAccordionSummaryStyles } from './components/MUIAccordionSummary'
import { inputOutlinedStyles } from './components/inputOutlined'

declare module '@mui/material/styles' {
  interface Theme {
    alternativePalette: {
      dark: {
        lighter: string
        main: string
      }
      primary: {
        50: string
        100: string
        200: string
        300: string
        500: string
        600: string
      }
    }
    gradients: {
      dark: string
    }
  }
  interface ThemeOptions {
    alternativePalette: {
      dark: {
        lighter: string
        main: string
      }
      primary: {
        50: string
        100: string
        200: string
        300: string
        500: string
        600: string
      }
    }
    gradients: {
      dark: string
    }
  }
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    laptop: true
  }
}
export const theme = createTheme({
  palette,
  shadows,
  breakpoints,
  spacing: 4,
  typography,
  components: {
    MuiOutlinedInput: inputOutlinedStyles,
    MuiAccordionSummary: MUIAccordionSummaryStyles,
  },
  alternativePalette: {
    dark: {
      lighter: 'rgb(66, 66, 74)',
      main: 'rgb(52, 71, 103)',
    },
    primary: {
      50: '##E7F0FD',
      100: '#C6DCF9',
      200: '#B6D2F8',
      300: '#e3f2fd',
      500: '#95BFF5',
      600: '#6DA6F1',
    },
  },
  gradients: {
    dark: 'linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))',
  },
})
